(function () {
        'use strict';

        class interventionService {
            constructor($q, $http, mnWebSocket, Upload) {
                this.q = $q;
                this.http = $http;
                this.ws = mnWebSocket;
                this.Upload = Upload;
            }

            static get $inject() {
                return ["$q", "$http", "mnWebSocket", "Upload"];
            }

            getInterventions() {
                const deferred = this.q.defer();
                let url = `/api/intervention/`;
                this.http.get(url)
                    .then(success, deferred.reject);

                function success(response) {
                    deferred.resolve(response.data);
                }

                return deferred.promise;
            }

            getIntervention(pk) {
                const deferred = this.q.defer();
                let url = `/api/intervention/${pk}/`;
                this.http.get(url)
                    .then(success, deferred.reject);

                function success(response) {
                    deferred.resolve(response.data);
                }

                return deferred.promise;
            }

            saveIntervention(e) {
                let deferred = this.q.defer();
                let url = `/api/intervention/${!_.isNil(e.id) ? e.id + "/" : ""}`;
                this.http.post(url, e).then(successCallback, errorCallback);

                function successCallback(response) {

                    deferred.resolve(response.data);
                }

                function errorCallback(err) {
                    deferred.reject(err);
                }

                return deferred.promise;
            }

            deleteIntervention(pk) {
                let deferred = this.q.defer();
                let url = `/api/intervention/${pk}/`;

                this.http.delete(url)
                    .then(success.bind(this), deferred.reject);

                function success(response) {
                    deferred.resolve(response.data);
                }

                return deferred.promise
            }
        }

        module.exports = interventionService;
    }
)
();

/**
 * Created by amine on 01/12/2018.
 */


(function () {
    'use strict';

    class InterventionStepsCtrl {
        constructor($http, $scope, $mdDialog) {

            this.$http = $http;
            this.$scope = $scope;
            this.dialog = $mdDialog;
        }

        static get $inject() {
            return ["$http", "$scope", "$mdDialog"];
        }

        $onInit() {
        }


    }

    module.exports = {
        controllerAs: "vm",
        bindings: {
            model: "=Mnmodel",
            steps: "=steps"
        },
        template: require('cam/views/intervention-steps.html'),
        controller: InterventionStepsCtrl,
    };

})();

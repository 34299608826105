/**
 * Created by amine on 08/03/2018.
 */
(function () {
    'use strict';

    const getObjectId = require('shared/utils/get-object-id');

    module.exports = CareSheetFormCtrl;

    CareSheetFormCtrl.$inject = ["patientService", "system", "$mdDialog", "$translate", "dentalService", "configService",
        "careSheetService", "$stateParams", "$q", "visitService", "dragulaService", "$scope", "moment", "organizationService"];

    function CareSheetFormCtrl(patientService, system, $mdDialog, $translate, dentalService, configService,
                               careSheetService, $stateParams, $q, visitService, dragulaService, $scope, moment, organizationService) {
        let vm = this;
        let dateFormat = system['date_format'].js;

        vm.$onInit = init;
        vm.submit = submit;
        vm.validateCareSheet = validation(true);
        vm.invalidateCareSheet = validation(false);
        vm.deleteCareSheet = deleteCareSheet;
        vm.removePatient = removePatient;
        vm.patientCallback = patientCallback;
        vm.selectVisit = selectVisit;
        vm.selectDentalConsultation = selectDentalConsultation;
        vm.selectDentalBaseQuotation = selectDentalBaseQuotation;
        vm.careSheetTotal = careSheetTotal;
        vm.insuranceSelected = insuranceSelected;
        vm.getTitle = getTitle;
        vm.getDetails = getDetails;
        vm.dentalLineText = dentalLineText;
        vm.addProcedure = addProcedure;
        vm.removeProcedure = removeProcedure;
        vm.removeDetail = removeDetail;
        vm.addCustomProcedures = addCustomProcedures;
        vm.discountedPriceToggled = discountedPriceToggled;
        vm.getMedicalInsurances = getMedicalInsurances;
        vm.updateInsurance = updateInsurance;
        vm.insurancesSelectHtml = insurancesSelectHtml;
        vm.cancel = cancel;

        function init() {
            const prePromises = [organizationService.getOrganizations()]

            vm.isDental = configService.isDental();
            vm.filter = {
                is_deleted: {"$ne": true},
                is_draft: {"$ne": true},
                //has_visit: {"$eq": true},
                //medical_insurances: {"$ne": []}
            };
            vm.promise = null;
            vm.table_details = null;
            vm.patient = null;
            vm.visit = null;

            vm.insurances = [];
            vm.selectedInsurance = null;
            vm.sheet = {
                sheet_number: null,
                sheet_date: moment().format(dateFormat),
                details: [],
                patient: null,
                insurance: null,
                insurance_instance: null,
                use_discounted_price: true
            };

            dragulaService.options($scope, "line-bag", {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });


            vm.promise = $q.all(prePromises)
                .then(data => {
                    vm.organizations = data[0];

                    if (!_.isNil($stateParams.sheetId)) loadSheet();
                    else if (!_.isNil($stateParams.visitId)) {
                        if (vm.isDental) {
                            visitService
                                .getVisitOrDentalConsultation($stateParams.visitId)
                                .then(data => {
                                    if (_.has(data, 'visit')) loadFromDentalConsultation(data);
                                    else loadFromVisit(data);
                                }, () => careSheetService.mainState());
                        } else {
                            visitService
                                .getVisit($stateParams.visitId)
                                .then(loadFromVisit, () => careSheetService.mainState());
                        }
                    } else if (!_.isNil($stateParams.patientId)) patientCallback({id: $stateParams.patientId});

                    function loadFromVisit(visit) {
                        patientCallback({id: visit.patient_id});
                        loadVisits([visit])
                    }

                    function loadFromDentalConsultation(consult) {
                        patientCallback({id: consult.visit.patient_id});
                        loadDentalConsultation([consult])
                    }
                });

            vm.title = $translate.instant('new_care_sheet');

        }

        function loadSheet() {
            vm.promise = careSheetService.getCareSheet($stateParams.sheetId)
                .then(data => {
                    vm.sheet = _.assign(vm.sheet, data);
                    vm.title = $translate.instant('edit_care_sheet', {
                        patient: vm.sheet.patient.full_name,
                        date: vm.sheet.sheet_date
                    });
                    vm.getMedicalInsurances();
                });
        }

        function patientCallback(patient) {
            vm.promise = patientService.getFormPatient(patient.id, false)
                .then(data => {
                    vm.sheet.patient = data;
                    vm.getMedicalInsurances();
                });
        }

        function getTitle(detail) {
            if (_.get(detail, "is_visit_detail", false)) {
                return `${_.get(vm.sheet, "patient.full_name", "")} ${$translate.instant("visit_at")} ${detail.visit.visit_date}`
            } else if (_.get(detail, "is_dental_detail", false)) {
                let secondSeg = `${$translate.instant("visit_at")} ${_.get(detail, "dental_consultation.visit_date", null)}`;
                switch (_.get(detail, "_cls", "DentalConsultationCareSheetDetail")) {
                    case "DentalQuotationCareSheetDetail":
                        let dentalQuotation = _.get(detail, "dental_quotation", {});
                        let quotationTitle = dentalQuotation.title ? dentalQuotation.title : $translate.instant("care_sheet_untitled_quotation");

                        secondSeg = `| ${quotationTitle} [${dentalQuotation.creation_date}]`;
                        break;
                    case "DentalPlanCareSheetDetail":
                        let dentalPlan = _.get(detail, "dental_plan", {});
                        let planTitle = dentalPlan.title ? dentalPlan.title : $translate.instant("care_sheet_untitled_plan");

                        secondSeg = `| ${planTitle} [${dentalPlan.creation_date}]`;
                        break
                }

                return `${_.get(vm.sheet, "patient.full_name", "")} ${secondSeg}`
            } else {
                return $translate.instant("care_sheet_custom_procedures");
            }

        }

        function removePatient() {
            vm.sheet.patient = null;
            vm.sheet.insurance = null;
            vm.sheet.insurance_instance = null;
        }

        function insuranceSelected() {
            if (_.isNumber(vm.selectedInsurance)) {
                const organization = _.find(vm.organizations, ['id', vm.selectedInsurance]);

                updateInsurance(organization);
            } else {
                vm.sheet.insurance_instance = _.cloneDeep(vm.insurances[selectedPatientInsurance()]);
                vm.sheet.insurance = vm.sheet.insurance_instance.uid;
            }
        }

        function updateInsurance(organization = null) {
            if (organization) {
                const uid = getObjectId();
                vm.sheet.insurance = uid;
                vm.sheet.insurance_instance = {
                    organization,
                    uid,
                    draft: true,
                    from_care_sheet: true,
                    affiliate: 'PATIENT'
                };

                vm.insurances.push(_.cloneDeep(vm.sheet.insurance_instance));
                vm.selectedInsurance = uid;
            } else if (!vm.sheet.insurance_instance.is_edited) {
                const objectId = getObjectId();

                vm.sheet.insurance_instance.is_edited = true;
                vm.sheet.insurance_instance.uid = objectId;
                vm.sheet.insurance = objectId;
                vm.sheet.insurance_instance.draft = true;

                vm.insurances.push(_.cloneDeep(vm.sheet.insurance_instance));
                vm.selectedInsurance = objectId;
            } else {
                vm.insurances[selectedPatientInsurance()] = vm.sheet.insurance_instance;
            }
        }

        function getMedicalInsurances(force = false) {
            vm.insurances = _.chain(vm.sheet).get("patient.medical_insurances", []).cloneDeep().value()

            if (_.isEmpty(vm.insurances)) {
                updateInsurance(_.get(configService.defaultValues, 'organization', vm.organizations[0]));
            } else {
                if (!force && vm.sheet.id && !_.chain(vm.insurances).map("uid").includes(vm.sheet.insurance).value()) {
                    vm.insurances = _.concat(vm.insurances, _.cloneDeep(vm.sheet.insurance_instance));
                    vm.selectedInsurance = vm.sheet.insurance;
                }
            }
        }

        function insurancesSelectHtml() {
            if (_.isNumber(vm.selectedInsurance)) {
                const organization = _.find(vm.organizations, ['id', vm.selectedInsurance]);
                return `<span>
                            ${organization.name}
                        </span>`;
            } else {
                const insurance = vm.insurances[selectedPatientInsurance()];

                if (insurance) return `<span ${insurance.draft ? 'class="draft"' : ''}>
                            <span>
                                ${insurance.organization.name}
                            </span>
                            <span> | </span>
                            <span>${$translate.instant("affiliate_number")}</span>
                            <span>${insurance.affiliate_number || '-'}</span>
                        </span>`;
                return `<span>${$translate.instant("care_sheet_patient_insurance_placeholder")}</span>`;
            }
        }

        function selectVisit(event) {
            let dialog = require('billing/dialogs/visit-search-dialog');
            $mdDialog.show(_.assign({}, dialog, {
                targetEvent: event,
                locals: {
                    beneficiary: vm.sheet.patient,
                    beneficiary_type: "PATIENT"
                }
            })).then(loadVisits, _.noop);
        }

        function loadVisits(visits) {
            _.forEach(visits, prepareVisit);

            function prepareVisit(visit) {
                const proceduresPrices = _.get(visit, "financial_status.procedures_prices", []);
                const correction = _.get(visit, "financial_status.procedures_prices_correction", 0);

                vm.sheet.details.push({
                    visit: visit,
                    _cls: "VisitCareSheetDetail",
                    procedures: getCorrectedProcedures(visit.procedures, proceduresPrices, correction, prepareProcedures),
                    is_visit_detail: true,
                    isNew: true
                });
            }
        }

        function selectDentalConsultation(event) {
            let dialog = require('care-sheet/dialogs/dental-consultation-search-dialog');
            $mdDialog.show(_.assign({}, dialog, {
                targetEvent: event,
                locals: {
                    patient: vm.sheet.patient
                }
            })).then(loadDentalConsultation, _.noop);
        }

        function loadDentalConsultation(consults) {
            _.forEach(consults, prepareDentalConsult);

            function prepareDentalConsult(consult) {
                if (consult.teeth_procedures.length > 0) {
                    const proceduresPrices = _.get(consult, "procedures_prices", []);
                    const correction = _.get(consult, "procedures_prices_correction", 0);

                    vm.sheet.details.push({
                        dental_consultation: consult,
                        _cls: "DentalConsultationCareSheetDetail",
                        procedures: getCorrectedProcedures(consult.teeth_procedures, proceduresPrices, correction, prepareDentalProcedures),
                        is_dental_detail: true,
                        isNew: true
                    });
                }
            }
        }

        function selectDentalBaseQuotation(event, treatmentPlan = false) {
            let dialog = require('care-sheet/dialogs/dental-base-quotation-search-dialog');
            $mdDialog.show(_.assign({}, dialog, {
                targetEvent: event,
                locals: {
                    patient: vm.sheet.patient,
                    type: treatmentPlan ? "treatment-plan" : "quotation"
                }
            })).then(quotations => loadDentalQuotations(quotations, treatmentPlan), _.noop);
        }

        function loadDentalQuotations(quotations, treatmentPlan = false) {
            _.forEach(quotations, quotation => {
                dentalService[treatmentPlan ? "planDetail" : "getQuotationDetail"](quotation)
                    .then(fullQuotation => {
                        const proceduresPrices = _.get(fullQuotation, "procedures_prices", []);
                        const correction = _.get(fullQuotation, "procedures_prices_correction", 0);

                        vm.sheet.details.push({
                            [treatmentPlan ? "dental_plan" : "dental_quotation"]: quotation,
                            _cls: treatmentPlan ? "DentalPlanCareSheetDetail" : "DentalQuotationCareSheetDetail",
                            procedures: getCorrectedProcedures(fullQuotation.teeth_procedures, proceduresPrices, correction, prepareDentalProcedures),
                            is_dental_detail: true,
                            isNew: true
                        });
                    })
            });
        }

        function addCustomProcedures(data) {
            let detail = _.find(vm.sheet.details, {visit: null});
            if (_.isNil(detail)) {
                vm.sheet.details.push({
                    _cls: "CareSheetBaseDetail",
                    procedures: _.reduce(data, prepareProcedures, [])
                });
            } else {
                detail.procedures = _.concat(detail.procedures, _.reduce(data, prepareProcedures, []));
            }
        }

        function handleProceduresPrices(proceduresPrices, correction = 0, field = "uid") {
            const minIndex = _.indexOf(proceduresPrices, _.minBy(proceduresPrices, 'taxed_amount'));

            return (item, index) => {
                const procedurePrice = _.find(proceduresPrices, ["uid", item[field]]) || _.get(proceduresPrices, index);

                if (_.has(item, "price")) {
                    item.original_price = item.price - _.get(item, "discount", 0);
                } else {
                    item.original_price = _.get(procedurePrice, "price", 0) - _.get(procedurePrice, "discount", 0);
                }

                item.discounted_price = _.get(procedurePrice, "taxed_amount", 0) + (index === minIndex ? correction : 0);
                item.price = vm.sheet.use_discounted_price ? item.discounted_price : item.original_price;

                return item;
            }
        }

        function getCorrectedProcedures(procedures, proceduresPrices, correction, reduce, field = "uid") {
            procedures = _.chain(procedures)
                .map(handleProceduresPrices(proceduresPrices, correction, field))
                .reduce(reduce, [])
                .value();

            return procedures;
        }


        function prepareProcedures(result, item) {
            if (_.has(item, "procedure")) {
                if (_.get(item.procedure, "is_refundable", false)) {
                    result.push({
                        name: item.name,
                        code: item.code || item.procedure.code,
                        discounted_price: item.discounted_price,
                        original_price: item.original_price,
                        price: item.price,
                        qte: _.isNil(item.qte) ? 1 : item.qte,
                        procedure: item.procedure,
                        comment: ""
                    });
                }
            } else {
                if (_.get(item, "is_refundable", false)) {
                    result.push({
                        name: item.name,
                        code: item.code || item.procedure.code,
                        discounted_price: item.price,
                        original_price: item.price,
                        price: item.price,
                        qte: 1,
                        procedure: item,
                        comment: ""
                    });
                }
            }

            return result;
        }

        function prepareDentalProcedures(result, item) {
            if (item.dental_procedure) {
                if (_.get(item.dental_procedure, "procedure_is_refundable", false)) {
                    result.push({
                        name: item.name,
                        applied_at: dentalLineText(item),
                        code: item.code,
                        discounted_price: item.discounted_price,
                        original_price: item.original_price,
                        price: item.price,
                        qte: _.isNil(item.qte) ? 1 : item.qte,
                        dental_procedure: item.dental_procedure,
                        procedure: {id: _.get(item.dental_procedure, "procedure_id", null)},
                        comment: item.comment,
                        teeth: item.teeth
                    });
                }
            } else if (item.dental_procedure_id) {
                if (_.get(item, "procedure_is_refundable", false)) {
                    result.push({
                        name: item.name,
                        applied_at: dentalLineText(item),
                        code: item.code,
                        discounted_price: item.discounted_price,
                        original_price: item.original_price,
                        price: item.price,
                        qte: _.isNil(item.qte) ? 1 : item.qte,
                        dental_procedure: {id: _.get(item, "dental_procedure_id", null)},
                        procedure: {id: _.get(item, "procedure_id", null)},
                        comment: item.comment,
                        teeth: item.teeth
                    });
                }
            }

            return result;
        }

        function careSheetTotal() {
            return _.sumBy(vm.sheet.details, totalSum);

            function totalSum(detail) {
                return _.sumBy(detail.procedures, detailSum);
            }

            function detailSum(subDetail) {
                return (subDetail.qte * subDetail.price) || 0;
            }
        }

        function getDetails() {
            return _.orderBy(vm.sheet.details, (item) => {
                if (!_.isNil(item.visit)) return moment(item.visit.visit_date, dateFormat).valueOf();
                if (!_.isNil(item.dental_consultation)) return moment(item.dental_consultation.visit_date, dateFormat).valueOf();

                return 9999999999999;
            }, "asc")
        }

        function dentalLineText(line) {
            if (_.isEmpty(line.teeth)) {
                return "-"
            } else if (line.teeth.length > 4) {
                return `${$translate.instant("care_sheet_dental_applied_to")} ${$translate.instant("dental_setup_is_multi_teeth")}`
            } else {
                return `${$translate.instant("care_sheet_dental_applied_to")} ${line.teeth.join(",")}`;
            }
        }

        function discountedPriceToggled(use) {
            const allowedDetailsTypes = ["VisitCareSheetDetail", "DentalConsultationCareSheetDetail", "DentalPlanCareSheetDetail", "DentalQuotationCareSheetDetail"];
            use = use || vm.sheet.use_discounted_price;

            _.forEach(vm.sheet.details, detail => {
                if (allowedDetailsTypes.includes(detail._cls) && detail.isNew) {
                    _.forEach(detail.procedures, item => {
                        item.price = use ? item.discounted_price : item.original_price;
                    });
                }
            })
        }

        function selectedPatientInsurance() {
            return _.findIndex(vm.insurances, ['uid', vm.selectedInsurance]);
        }

        function submit(exit) {
            if (_.isNil(exit)) exit = false;

            const patient = _.cloneDeep(vm.sheet.patient);
            const promises = [
                careSheetService.saveCareSheet(vm.sheet),
            ];

            if (!vm.sheet.patient.is_insured) {
                const insurance = _.cloneDeep(vm.sheet.insurance_instance);
                patient.is_insured = true;
                insurance.from_care_sheet = null;
                patient.medical_insurances = [insurance];

                promises.push(patientService.updateMedicalInsurances(patient));
            } else if (vm.sheet.saveMedicalInsurance) {
                const insurance = _.cloneDeep(vm.sheet.insurance_instance);

                if (insurance.is_edited || insurance.from_care_sheet) {
                    const index = _.findIndex(vm.insurances, ['organization.id', insurance.organization.id]);
                    insurance.is_edited = null;
                    insurance.from_care_sheet = null;

                    if (index > -1) {
                        patient.medical_insurances[index] = insurance;
                    } else {
                        patient.medical_insurances.push(insurance)
                    }

                    promises.push(patientService.updateMedicalInsurances(patient));
                }
            }

            return $q.all(promises).then(data => {
                if (exit) careSheetService.mainState();
                else {
                    if (data[0].id !== vm.sheet.id) careSheetService.formState({sheetId: data[0].id});
                    vm.sheet = _.assign({}, vm.sheet, data[0]);
                    if (data[1]) vm.sheet.patient = data[1];

                    getMedicalInsurances();
                }
            }, _.noop);
        }

        function validation(validate) {
            return function () {
                if (_.isNil(vm.sheet.id)) {
                    vm.sheet.is_validated = validate;
                    return submit()
                } else {
                    return $mdDialog
                        .show(careSheetService.careSheetValidationDialog(validate))
                        .then(confirm, _.noop)
                }
            };

            function confirm() {
                if (validate) careSheetService.validateCareSheet(vm.sheet)
                    .then(success);
                else careSheetService.invalidateCareSheet(vm.sheet)
                    .then(success);
            }

            function success(sheet) {
                vm.sheet.is_validated = sheet.is_validated
            }
        }

        function deleteCareSheet() {
            $mdDialog
                .show(careSheetService.careSheetDeletingDialog())
                .then(confirm, _.noop);

            function confirm() {
                careSheetService.deleteCareSheet(vm.sheet)
                    .then(() => careSheetService.mainState());
            }

        }

        function removeDetail(detail) {
            _.remove(vm.sheet.details, detail)
        }

        function addProcedure(detail, ev) {
            const dialog = require('billing/dialogs/procedure-search-dialog');
            $mdDialog.show(_.assign(dialog, {
                targetEvent: ev,
                locals: {
                    search: {interdict_sale: {$ne: true}, is_deleted: {$ne: true}}
                }
            })).then(function (data) {
                detail.procedures = _.reduce(data, prepareProcedures, detail.procedures);
            });
        }

        function removeProcedure(item, detail) {
            _.remove(detail.procedures, item)
        }

        function cancel() {
            careSheetService.mainState();
        }
    }
})();
/**
 * Created by Hp on 12/05/2017.
 */
(function () {

    'use strict';

    class SparePartsListDialogCtrl {
        constructor($mdDialog) {
            this.$mdDialog = $mdDialog;
            this.selected = [];
            this.cancel = this.$mdDialog.cancel;
            this.addItem = this.addItem.bind(this);

        }

        static get $inject() {
            return ["$mdDialog"];
        }

        $onInit() {
            this.actions = {
                single: [],
                multiple: [
                    {
                        icon: 'mdi-plus',
                        label: 'add',
                        resource: 'spare-part',
                        action: 'create',
                        behavior: 'remove',
                        method: this.addItem,
                        isStatic: true
                    }]
            };
        }

        addSelection() {
            this.$mdDialog.hide(this.selected);
        }

        addItem(ev) {
            const dialogConfig = _.assign({}, SPARE_PART_FORM_DIALOG, {
                targetEvent: ev,
                template: require('../views/spare-part-dialog.html'),
                locals: {},
            });
            this.$mdDialog.show(dialogConfig).then(this.reset);
        }
    }

    class SparePartFormDialogCtrl {
        constructor($mdDialog, $http) {
            this.http = $http;
            this.dialog = $mdDialog;


            this.model = this.model || {price: 0};
        }

        static get $inject() {
            return ["$mdDialog", "$http"];
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.http.post('/api/spare-part/', this.model)
                .then(response => this.dialog.hide(response.data));
        }
    }


    const SPARE_PART_FORM_DIALOG = {
        locals: {},
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: SparePartFormDialogCtrl,
        focusOnOpen: false
    };

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: SparePartsListDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("../views/spare-parts-list-dialog.html"),
    };
})();
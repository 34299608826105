(function () {
    'use strict';
    const {HTMLS} = require('shared/utils/requires');

    class CamConfigItemCtrl {
        constructor($state, $mdToast, camConfigService, $mdDialog, $translate, $stateParams) {
            this.$state = $state;
            this.$mdToast = $mdToast;
            this.camConfigService = camConfigService;
            this.$mdDialog = $mdDialog;
            this.$translate = $translate;
            this.$stateParams = $stateParams;


            this.dialogTpl = this.$stateParams['dialogTpl'];
            this.apiUrl = this.$stateParams['apiUrl'];
            this.resource = this.$stateParams['resource'];
            this.mnModel = this.$stateParams['mnModel'];

            this.editItem = this.editItem.bind(this);
            this.deleteItem = this.deleteItem.bind(this);
        }

        static get $inject() {
            return ["$state", "$mdToast", "camConfigService", "$mdDialog", "$translate", "$stateParams"];
        }

        $onInit() {
            this.actions = {
                single: [{
                    icon: 'mdi-pencil',
                    label: 'edit',
                    resource: this.resource,
                    action: 'update',
                    behavior: 'remove',
                    method: this.editItem
                }, {
                    icon: 'mdi-delete',
                    label: 'delete',
                    resource: this.resource,
                    action: 'delete',
                    behavior: 'remove',
                    method: this.deleteItem
                }],
                multiple: [{
                    icon: 'mdi-plus',
                    label: 'add',
                    resource: 'spare-part',
                    action: 'create',
                    behavior: 'remove',
                    method: this.editItem,
                    isStatic: true
                }]
            }
            this.toggle = false;
            this.filter = {is_deleted: {$ne: true}};
        }

        editItem(item, ev) {
            const dialogConfig = _.assign({}, require('cam/dialogs/config-item-edit-dialog'), {
                targetEvent: ev,
                template: HTMLS[this.dialogTpl],
                locals: {
                    url: this.apiUrl,
                    model: _.cloneDeep(item)
                },
            });

            this.$mdDialog.show(dialogConfig).then(this.reset);
        }

        deleteItem(item, ev) {
            this.promise = this.camConfigService.deleteItem(item.id, this.apiUrl).then(this.reset, (err) => {
                this.$mdToast.show(this.$mdToast.simple()
                    .textContent(this.$translate.instant(this.$translate.instant('impossible_delete'))
                        .position("bottom left")
                        .hideDelay(1500)));
            });
        }

    }

    module.exports = CamConfigItemCtrl;

})()